import React, { useEffect, useState } from "react";

import styles from "./styles.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {


  const navigate = useNavigate();

  const scrollToSection = (page, id) => {
    navigate(`/${page}?${id}`);
  }


  return (
    <>
      <footer class="footerSection bg-footer">
        <div class="grid grid-cols-3 lg:grid-cols-6">
          <div class="footerLogoContainer  lg:col-span-2">
            <img src="footerLogo.png" />
            <p class="para text-xs md:text-xs lg:text-base">
              Diverse Horizons, <br /> Unified Excellence
            </p>
          </div>
          <div class="list">
            <h6 class="footer-heading text-xs md:text-xs lg:text-base">About Us</h6>
            <ul class="list-unstyled footer-link mt-2">
              <li className="">
                <span onClick={() => scrollToSection("about", "who_we_are")} className="text-xs md:text-xs lg:text-base">Who We Are </span>
              </li>
              <li>
                <span onClick={() => scrollToSection("about", "growth_story")} className="text-xs md:text-xs lg:text-base">Our Growth Story</span>
              </li>
              <li>
                <span onClick={() => scrollToSection("about", "our_values")} className="text-xs md:text-xs lg:text-base">Our Values</span>
              </li>
              <li>
                <span onClick={() => scrollToSection("about", "our_leaders")} className="text-xs md:text-xs lg:text-base">Our Leaders</span>
              </li>
            </ul>
          </div>
          <div class="list">
            <h6 class="footer-heading text-xs md:text-xs lg:text-base">Sectors</h6>
            <ul class="list-unstyled footer-link mt-2">
              <li>
                <span onClick={() => scrollToSection("sectors" ,"education")} className="text-xs md:text-xs lg:text-base">Education</span>
              </li>
              <li>
                <span onClick={() => scrollToSection("sectors" ,"real_estate")} className="text-xs md:text-xs lg:text-base">Real Estate</span>
              </li>
              <li>
                <span onClick={() => scrollToSection("sectors" ,"financial_invests")}   className="text-xs md:text-xs lg:text-base">Financial Investments</span>
              </li>
              <li>
                <span onClick={() => scrollToSection("sectors" ,"agriculture")}  className="text-xs md:text-xs lg:text-base">Agriculture</span>
              </li>
            </ul>
          </div>
          <div class="list col-start-2 lg:col-span-1">
            <h6 class="footer-heading text-xs md:text-xs lg:text-base">
              Philanthrophy
            </h6>
            <ul class="list-unstyled footer-link mt-2">
              <li>
                <span onClick={() => navigate("/philanthrophy")} className="text-xs md:text-xs lg:text-base">Hyacinth & Nello Trust</span>
              </li>
            </ul>
          </div>
          <div class="list col-span-1">
            <h6 class="footer-heading text-xs md:text-xs lg:text-base">
              Contact & Social
            </h6>
            <ul class="list-unstyled footer-link mt-2">
              <li>
                <span onClick={() => navigate("/contact")} className="text-xs md:text-xs lg:text-base">Contact Us</span>
              </li>
              <li>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-xs md:text-xs lg:text-base">Twitter</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/ae-group-india/" target="_blank" rel="noopener noreferrer" className="text-xs md:text-xs lg:text-base">Linkedln</a>
              </li>
              <li>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-xs md:text-xs lg:text-base">Facebook</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="copyRightContainer">
          <div class="copyRight">
            <p className="footer-alt text-xs md:text-xs lg:text-base">© 2024 AE Group. All rights reserved.</p>
          </div>
          {/* <div class="socialIconContainer">
            <div class="icon">
              <ul class="list-inline footer-social-icons">
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fab fa-facebook-f footer-social-icon facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fab fa-linkedin-in footer-social-icon linkedin"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fab fa-linkedin-in footer-social-icon linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </footer>
    </>
  );
}
