import React from 'react'

import "./styles.css";

function SectorsInfoSection(props) {
  return (
    <section className='philsInfo'>
    <div className="sectorInfoSection grid grid-cols-1 sm:grid-cols-2">
      {
        props.imageDirection == "right" && (
          <div className="imgContainer">
            <img src={props.imageSrc} />
          </div>
        )
      }
      <div className="content">
        {props.title && <h1 className="header text-2xl md:text-4xl lg:text-6xl">{props.title}</h1>}
        {props.subTitle && <span className='subHeader text-xs md:text-sm lg:text-base'>{props.subTitle}</span>}
        {props.summary && <p className="summary text-base md:text-lg lg:text-xl">
          {props.summary}
        </p>
        }
      </div>
      {
        props.imageDirection == "left" && (
          <div className="imgContainerl">
            <img src={props.imageSrc} />
          </div>
        )
      }

    </div>
    </section>
  )
}

export default SectorsInfoSection