import React, { useEffect } from "react";

import Header from "../../components/Header";

import Footer from "../../components/Footer";


import {  ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./styles.css";

import ContactCallUs from "../../components/ContactCallUs";

export default function ContactPage() {

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 })

  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://ae-group.in/contact.php", {
      // Adding method type
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        name: e.target.form[0].value,
        mail: e.target.form[1].value,
        message: e.target.form[2].value
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then((result) => {
      toast("Your message has been sent successfully. We will get back to you shortly")
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("Not able to send mail", error);
        }
      )
  };

  return (
    <>
      <Header />

      <section className="contactUsSection">
        <div className="Contact-sec2-Container grid sm:grid-cols-1 md:grid-cols-2">
          <div className="">
            <h2 className="contact-sec2head  text-2xl md:text-3xl lg:text-6xl">
              Contact Us
            </h2>
            <p className="contact-sec2para">We’d love to hear from you</p>
            <form method="post" action="/server.php" className="contactFormContainer">
              <div className="form-group">
                <label for="name">First Name</label>
                <input type="text" id="name" name="name" placeholder="First Name" required />
              </div>
              <div className="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" name="email" placeholder="you@company.com" required />
              </div>
              <div className="form-group">
                <label for="message">Message</label>
                <textarea id="message" name="message" placeholder="Leave us a message..." rows="7" required></textarea>
              </div>
              <button onClick={(e) => handleSubmit(e)} className="button">Submit</button>
            </form>
          </div>
          <div className="map-reponsive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15225.243970338319!2d78.499212!3d17.444823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a3deada3157%3A0xc930bc55f56756a3!2sAE%20Group!5e0!3m2!1sen!2sin!4v1709762355604!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <ContactCallUs />
        <Footer />
      </section>

      <ToastContainer />
    </>
  );
}
