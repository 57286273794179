import React, { useEffect } from "react";
import Header from "../../components/Header";
import SectorsInfoSection from "../../components/SectorInfoSection";
import Footer from "../../components/Footer";

import "./styles.css";

export default function Philanthrophy() {
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 })

  }, [])

  const sectorsData = [
    {
      title: "The Hyacinth and Nello Charity Trust",
      subTitle: "A legacy of service",
      summary2: "Mother Teresa",
      summary:
        "We know only too well that what we are doing is nothing more than a drop in the ocean. But if the drop were not there, the ocean would be missing something.",
      imageSrc: "/charity.png",
      imageDirection: "right",
    },
    {
      summary:
        "We are a family foundation that believes in the capacity of non-profit organizations to transform communities for the better, improve lives, and advance ideas. Over the years, we have developed programmes focused on giving back to society while also reflecting a widening circle of compassion, demonstrated by our support of community welfare, education, healthcare, and more.",
      imageSrc: "/charity2.jpg",
      imageDirection: "left",
    },
    {
      summary:
        "Mrs. Hyacinth Emanuel has been instrumental in the philanthropic endeavours of the AE group. In her (previous) role as Managing Director of St. Andrews Group, she has spearheaded the educational support of underprivileged children through direct financial support, free education schemes, sponsorships, and providing holistic resources to enhance students’ learning experiences.The Trust continues to support underprivileged students, destitute women and previous employees in its aim to connect the world through community and compassion. In this process, giving back to society and service to humanity remain steadfast cornerstones of the AE group’s philanthropic work.",
      imageSrc: "/charity3.jpg",
      imageDirection: "right",
    },
    {
      summary:
        "The newly established Hyacinth and Nello Charitable Trust further aims to give back to the wider community through funding, sponsoring community initiatives and supporting social causes intended to make the world a better place. Focusing on facilitating community centres for fellowship and worship, the Charitable Trust undertakes projects involving renovating and rebuilding community spaces.",
      imageSrc: "/charity4.jpg",
      imageDirection: "left",
    },
  ];

  return (
    <>
      <Header />
      <section className="phils">
        {sectorsData.map((sector) => {
          return (
            <SectorsInfoSection
              title={sector.title}
              subTitle={sector.subTitle}
              summary={sector.summary}
              imageSrc={sector.imageSrc}
              imageDirection={sector.imageDirection}
            />
          );
        })}
      </section>
      <div className="footerWrapper">
        <Footer />
      </div>
    </>
  );
}
