import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import SectorsPage from './pages/Sectors';
import ContactPage from './pages/ContactUs';
import Philanthrophy from './pages/Philanthrophy';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/sectors" element={<SectorsPage />} />
      <Route path="/philanthrophy" element={<Philanthrophy />} />
      <Route path="contact" element={<ContactPage />} />
    </Routes>
  )
}
