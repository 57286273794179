import React from "react";

import styles from "./styles.css";

export default function ContactCallUs() {
  return (
    <>
      <section className="contactSection bg-contact">
        <div className="callUsContainer">
          <div className="ContactLeContainer col-span-3">
          <div className="contactlist">
              <h6 className="Callushead text-base md:text-lg lg:text-xl">
                AE Group{" "}
              </h6>
              <p className="para text-xs md:text-sm lg:text-base">
              Head Office 222 B, San Marina <br /> House, West Marredpally, <br /> Secunderabad 500026
              </p>
              <div className="contactInformation">
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Phone :
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              +91 40 2770 5000	  
              </p>
              </div>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Email	:
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              mail@ae-group.in
              </p>
              </div>
              </div>

            </div>
          </div>
          <div className="call-us grid grid-cols-2 md:grid-cols-3">
            {/* <div className="contactlist">
              <h6 className="Callushead text-base md:text-lg lg:text-xl">
                AE Group{" "}
              </h6>
              <p className="para text-xs md:text-sm lg:text-base">
              Head Office 222 B, San Marina <br /> House, West Marredpally, <br /> Secunderabad 500026
              </p>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Phone :
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              +91 40 2770 5000	  
              </p>
              </div>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Email	:
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              mail@ae-group.in
              </p>
              </div>
            </div> */}
            
            {/* <div className="contactlist">
              <h6 className="Callushead text-base md:text-lg lg:text-xl">
                AE Group{" "}
              </h6>
              <p className="para text-xs md:text-sm lg:text-base">
              Head Office 222 B, San Marina <br /> House, West Marredpally, <br /> Secunderabad 500026
              </p>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Phone :
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              +91 40 2770 5000	  
              </p>
              </div>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Email	:
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              mail@ae-group.in
              </p>
              </div>
            </div>

            <div className="contactlist">
              <h6 className="Callushead text-base md:text-lg lg:text-xl">
                AE Group{" "}
              </h6>
              <p className="para text-xs md:text-sm lg:text-base">
              Head Office 222 B, San Marina <br /> House, West Marredpally, <br /> Secunderabad 500026
              </p>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Phone :
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              +91 40 2770 5000	  
              </p>
              </div>
              <div className="contact-info">
              <h6 className="Callushead1 text-base md:text-sm lg:text-base">
              Email	:
              </h6>
              <p className="para1 text-xs md:text-sm lg:text-base">
              mail@ae-group.in
              </p>
              </div>
            </div> */}
            {/* <div className="contactlist">
              <h6 className="Callushead md:text-lg lg:text-xl">Akira </h6>
              <p className="para text-xs md:text-sm lg:text-base">
                Sainikpuri, <br /> Hyderabad
              </p>
              <p className="para text-xs md:text-sm lg:text-base">
                +91 989898241
              </p>
            </div>
            <div className="contactlist">
              <h6 className="Callushead md:text-lg lg:text-xl"> Prime EA </h6>
              <p className="para text-xs md:text-sm lg:text-base">
                Sainikpuri, <br /> Hyderabad
              </p>
              <p className="para text-xs md:text-sm lg:text-base">
                +91 989898241
              </p>
            </div>
            <div className="contactlist">
              <h6 className="Callushead md:text-lg lg:text-xl">St Andrews </h6>
              <p className="para text-xs md:text-sm lg:text-base">
                Sainikpuri, <br /> Hyderabad
              </p>
              <p className="para text-xs md:text-sm lg:text-base">
                +91 989898241
              </p>
            </div>
            <div className="contactlist">
              <h6 className="Callushead md:text-lg lg:text-xl">
                {" "}
                AE Enterprises{" "}
              </h6>
              <p className="para text-xs md:text-sm lg:text-base">
                Sainikpuri, <br /> Hyderabad
              </p>
              <p className="para text-xs md:text-sm lg:text-base">
                +91 989898241
              </p>
            </div>
            <div className="contactlist">
              <h6 className="Callushead md:text-lg lg:text-xl">
                {" "}
                AE Private Equity{" "}
              </h6>
              <p className="para text-xs md:text-sm lg:text-base">
                Sainikpuri, <br /> Hyderabad
              </p>
              <p className="para text-xs md:text-sm lg:text-base">
                +91 989898241
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
