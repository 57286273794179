import React from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import InfoSectionComponent from '../../components/InfoSection';

import { Carousel } from 'react-bootstrap';


import "./styles.css";
import { useMediaQuery } from 'react-responsive';


const workFields = [
  {
    title: "Real Estate",
    summary: "Innovate landscapes with AE Group's excellence in engineering and construction, reshaping the future of infrastructure.",
    imageSrc: "/real-estate.png"
  },
  {
    title: "Agriculture",
    summary: "Harvest prosperity with AE Group's agricultural expertise, cultivating sustainable solutions for a thriving tomorrow.",
    imageSrc: "/agriculture.jpg"

  },
  {
    title: "Education",
    summary: "Nurture minds with educational brilliance – AE Group pioneers a transformative and empowering learning experience.",
    imageSrc: "/education.jpg"
  },
  {
    title: "Private Equity",
    summary: "Invest in success with AE Group's private equity initiatives, where strategic foresight meets efficient execution.",
    imageSrc: "/private-equity.jpg"
  },
]

export default function HomePage() {

  const isMobile = useMediaQuery({
    query: '(max-width: 856px)'
  })

  return (
    <>
      <Header />

      <section className="">
        <div className="introSectionContainer">
          <InfoSectionComponent
            title="Achieving excellence through efficient solutions"
            summary="Redefine success in engineering, construction, real estate, education, agriculture,
          and private equity. Elevate your projects with precision and innovation,
          forging a future where every endeavour thrives."
            imageSrc={isMobile ? '/homeSectionMobile.png' : '/homeSection1.jpg'}
            direction="right"
          />
        </div>

        <GrowthVenturesSection />

        <WorkSection />
        {/* <StatsSection />
      <Testimonials /> */}
      </section>
      <Footer />
    </>

  )
}

const GrowthVenturesSection = () => {
  return (
    <section className='growthSection'>
      <div class="growthLogoContainer col-container grid grid-cols-3 lg:grid-cols-6">
        <div class="growthLogo"><img src="brand1.png" alt="Brand 1" /></div>
        <div class="growthLogo"><img src="brand7.png" alt="Brand 2" /></div>
        <div class="growthLogo"><img src="brand5.png" alt="Brand 3" /></div>
        <div class="growthLogo"><img src="brand9.png" alt="Brand 4" /></div>
        <div class="growthLogo"><img src="brand10.png" alt="Brand 5" /></div>
        <div class="growthLogo"><img src="brand11.png" alt="Brand 6" /></div>
      </div>
      <div className="growthLogoContainer col-container grid grid-cols-3 lg:grid-cols-5">
        <div class="growthLogo"><img src="brand3.png" alt="Brand 7" /></div>
        <div class="growthLogo"><img src="brand8.png" alt="Brand 8" /></div>
        <div class="growthLogo"><img src="brand4.png" alt="Brand 8" /></div>
        <div class="growthLogo"><img src="brand2.png" alt="Brand 8" /></div>
        <div class="growthLogo"><img src="brand6.png" alt="Brand 8" /></div>
      </div>
    </section>
  )
}

const WorkSection = () => {
  return (
    <section className="workSection grid grid-cols-1 sm:grid-cols-2">
      <div className="content">
        <h1 className="header text-2xl md:text-3xl lg:text-7xl">Diverse Horizons, Unified Excellence</h1>
        <p className="summary text-sm lg:text-xl">From groundbreaking projects to nurturing minds and cultivating growth, our diverse expertise converges in unified excellence, shaping a comprehensive vision for a sustainable and prosperous future.</p>
      </div>


      <div className="workFields">
        {workFields.map((item) => {
          return (
            <WorkFieldCard title={item.title} summary={item.summary} imageSrc={item.imageSrc} />
          )
        })}
      </div>
    </section>
  )
}

const WorkFieldCard = ({ title, summary, imageSrc }) => {
  return (
    <div className="fieldsCardContainer">
      <div className="imgContainer">
        <img src={imageSrc} />
      </div>
      <div className="content">
        <span className="title text-base md:text-xl lg:text-2xl">{title}</span>
        <p className="summary text-xs md:text-sm lg:text-base">{summary}</p>
      </div>
    </div>
  )
};

const StatsSection = () => {
  const statsData = [
    {
      metrics: "400k+",
      field: "Students Graduated"
    },
    {
      metrics: "150+",
      field: "Successful projects launched"
    },
    {
      metrics: "10K",
      field: "Acres of cultivated land"
    },
    {
      metrics: "150L+",
      field: "Invested in visionary ventures"
    },
  ]
  return (
    <section class="statsSection">

      <h3 class="title text-base md:text-4xl">Our impact by the numbers</h3>

      <div class="statsContainer grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
        {
          statsData.map((stats) => {
            return (
              <div class="statsData">
                <div class="verticalLine"></div>
                <div class="metrics">
                  <span class="numbers">{stats.metrics}</span>
                  <span class="fields">{stats.field}</span>
                </div>
              </div>
            )
          })
        }
      </div>

    </section>
  )
}

const Testimonials = () => {

  return (
    <Carousel className="testimonials">
      <Carousel.Item>
        <div class="testimonial-wrapper">
          <img src="/test1.png" alt="" className="companyLogo" />

          <div className="reviewContainer">
            <p className="review text-2xl md:text-4xl">
              "Invaluable partnership with AE group transformed our vision into reality, delivering exceptional results in our project”.
            </p>
            <div className="reviewer">
              <span className="name text-xl md:text-3xl">— Mahesh Kumar</span>
              <span className="role text-base md:text-2xl">CEO, Provident LLP</span>
            </div>
          </div>
        </div>
      </Carousel.Item>

      {/* <Carousel.Item>
        <div class="testimonial-wrapper">
          <img src="/brand1.jpg" alt="" className="companyLogo" />

          <div className="reviewContainer">
            <p className="review text-2xl md:text-4xl">
              Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.
            </p>
            <span className="name text-xl md:text-3xl">-Mathilde Lewis</span>
            <span className="role text-base md:text-2xl">Head of Design, Layers</span>
          </div>
        </div>
      </Carousel.Item> */}
    </Carousel>
  )
}