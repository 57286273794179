import React, { useEffect, useRef } from "react";
import SectorsInfoSection from "../../components/SectorInfoSection";

import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";

function SectorsPage() {
  const location = useLocation();
  const params = location.search.substr(1);

  let educationRef = useRef(params);
  let realEstateRef = useRef(params);
  let financialInvestsRef = useRef(params);
  let agricultureRef = useRef(params);

  useEffect(() => {

    switch (params) {
      case "education":
        window.scrollTo({ behavior: 'smooth', top: educationRef.current.offsetTop })
        break;

      case "real_estate":
        window.scrollTo({ behavior: 'smooth', top: realEstateRef.current.offsetTop })
        break;

      case "financial_invests":
        window.scrollTo({ behavior: 'smooth', top: financialInvestsRef.current.offsetTop })
        break;  

      case "agriculture":
        window.scrollTo({ behavior: 'smooth', top: agricultureRef.current.offsetTop })
        break;
      default:
        window.scrollTo({ behavior: 'smooth', top: 0})

        break;
    }
  }, [params])


  return (
    <section>
      <Header />
      <section className="sectors">
        <section ref={educationRef} className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="content">
            <h1 className="header text-2xl md:text-4xl lg:text-6xl">
              Education
            </h1>
            <span className="subHeader text-xs md:text-sm lg:text-base">
              Schooling | Educational Services
            </span>
            <p className="summary text-base md:text-lg lg:text-xl">
              Founded in 1985, <strong>St. Andrews School</strong> has been a
              trailblazer in the field of education. Started in a rented
              property with the mission of providing quality education at
              affordable costs, St. Andrews School had since expanded to three
              schools in the twin cities of Hyderabad and Secunderabad. By 2019,
              it added two more schools under the umbrella of its{" "}
              <strong>Winmore Academy</strong> brand in Bengaluru.
            </p>
          </div>
          <div className="imgContainer">
            <img src="/educationSchool1.jpg" alt="" />
          </div> 
        </section>

        <section className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="imgContainerl">
            <img src="/educationSchool2.jpg" />
          </div>
          <div className="content">
            <p className="summary text-base md:text-lg lg:text-xl">
              From the word go, St. Andrews School has been a game changer –
              building on a strong foundation of traditional learning and
              teaching, the school opened its doors to holistic education,
              interactive classrooms, cutting-edge computer education, robust
              early childhood education, bespoke e-curricula, supportive and
              special education, and continuous professional development for
              teachers early on. Among the first in the state of Telangana has
              been its introduction of Linux-based computer education for
              kindergarten to class X as well as{" "}
              <strong>Akira by St. Andrews </strong>, a niche school for
              students with dyslexia and other learning disabilities.
            </p>
          </div>
        </section>

        <section className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="content">
            <p className="summary text-base md:text-lg lg:text-xl">
              In 2021,{" "}
              <strong>
                {" "}
                St. Andrews School partnered with Crimson Education Management
                Services
              </strong>{" "}
              to take schooling to the next level. With Crimson’s proven
              capability in scaling operations sustainably and expertise in
              building future-ready workforces, they have taken over the
              management of St Andrews and Winmore Academy and are enabling the
              group to realize its core mission – An Andrewite education for
              all.
            </p>
          </div>
          <div className="imgContainer">
            <img src="/educationSchool3.jpg" />
          </div>
        </section>

        <section className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="imgContainerl">
            <img src="/educationSchool4.jpg" />
          </div>
          <div className="content">
            <p className="summary text-base md:text-lg lg:text-xl">
              <strong> Akira by St. Andrews  </strong>
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              Akira by St. Andrews, in association with Madras Dyslexia
              Association (MDA), is leading the way in educating students with
              dyslexia and associated specific learning difficulties. Based in
              the twin cities of Hyderabad and Secunderabad, Akira by St.
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              Andrews offers personalised and intensive alternatives to
              mainstream education for up to 25 day scholars aged between 6 and
              11.
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              <br />
              The latest research, resources, measurable data, and specialist
              teaching ensure that all lessons, activities, and approaches are
              ideally suited for each individual, maximizing their learning
              potential and fostering a growth mindset.
            </p>
          </div>
        </section>

        <section className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="content">
            <p className="summary text-base md:text-lg lg:text-xl">
              <strong> Educational Services </strong>
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              Our specialist knowledge of administration in the school education
              sector along with our history of delivering innovative solutions
              has made us one of the go-to school educational services
              organizations in Telangana. From school construction to social
              media marketing, education remains one of our core sectors, with
              numerous projects completed in the last 38 years.
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
            We Provide:
              <ol class="custom-list">
              <li>Assistance in setting up administrative procedures </li>
                <li>Assistance in CBSE compliance</li>
                <li>HR Services</li>
                <li>Digital & IT Learning Solutions</li>
                <li>Social Media Support Services</li>
              </ol>
            </p>
          </div>
          <div className="imgContainer">
            <img src="/educationSchool5.jpg" />
          </div>
        </section>

        <section className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="imgContainerl">
            <img src="/educationSchool6.jpg" />
          </div>
          <div className="content">
            <p className="summary text-base md:text-lg lg:text-xl">
              <strong> Infrastructure Services  </strong>
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              Buildings, classrooms, laboratories, sports facilities, and other
              amenities are crucial to learning environments in schools and
              universities; there is a strong correlation between top-notch
              infrastructure and high-quality student outcomes. Having been in
              the field of education for over four decades, AE Group is
              positioned to provide turnkey execution and management consultancy
              services.
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
            We provide the following services:
              <ol class="custom-list">
                
                <li>Financial services and partnerships </li>
                <li>Identifying, leasing and procuring land </li>
                <li>Architectural support services  </li>
                <li>End-to-end project estimates </li>
                <li>Project execution planning </li>
                <li>Quality assurance and control  </li>
                <li>
                  Construction and set up as per school board guidelines
                </li>
                <li>Campus safety and security </li>
                <li>Sports fields and arena set up</li>
              </ol>
            </p>
          </div>
        </section>

        <section ref={agricultureRef} className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="content">
            <h1 className="header text-2xl md:text-4xl lg:text-6xl">AE Agro</h1>
            <span className="subHeader text-xs md:text-sm lg:text-base">
              Committed to sustainable agriculture
            </span>
            <p className="summary text-base md:text-lg lg:text-xl">
              Headquartered in Hyderabad, AE Agro is a multi-crop plantation
              company with over 400 acres in farms and plantations located at
              the Maharashtra - Goa Border. The company undertakes large scale{" "}
              <strong>
                cultivation of pepper, cashew, arecanut, rubber, and timber.
              </strong>{" "}
              At AE Agro, we leverage technology to not only maximize yield but
              also to keep processes more efficient and affordable.
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              We are also a socially responsible forestry and timber business.
              As a significant demand for timber products contributes to the
              unsustainable practice of felling trees that threatens our
              forests, our company is passionate about reversing this trend by
              engaging in sustainable tree farming, ensuring that our lands can
              sustain agricultural practices in the long run. What makes our
              products unparalleled is our commitment to the environment, the
              care we invest in improving and innovating our production
              processes, and our aim to reduce impacts on the planet.
            </p>
          </div>
          <div className="imgContainer">
            <img src="/educationSchool7.jpg" />
          </div>
        </section>

        <section ref={realEstateRef} className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="imgContainerl">
            <img src="/educationSchool8.jpg" />
          </div>
          <div className="content">
            <h1 className="header text-2xl md:text-4xl lg:text-6xl">
              Real Estate
            </h1>
            <span className="subHeader text-xs md:text-sm lg:text-base">
              {" "}
              Maximising value
            </span>
            <p className="summary text-base md:text-lg lg:text-xl">
              We are a full-service land acquisition and real estate company
              with proven expertise in educational, residential, community,
              commercial, industrial development, and property asset management.
              Honed by <strong>five decades of excellence,</strong> our cohesive
              approach to real estate services spans development, construction,
              acquisition, leasing, reselling, asset and property management.
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
              Our mission encompasses dual aims: maximising value and enhancing
              quality of life for the communities we serve. Having partnered
              with property majors such as <strong>Provident Housing</strong> to
              develop residential projects in Bangalore, we are also currently
              active across real estate markets in Telangana, Tamil Nadu,
              Karnataka, Kerala, Maharashtra, and Goa.
            </p>
          </div>
        </section>

        <section ref={financialInvestsRef} className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="content">
            <h1 className="header text-2xl md:text-4xl lg:text-6xl">
            Financial Investments
            </h1>
            <p className="summary text-base md:text-lg lg:text-xl">
           Investing in Tomorrow <br /> <br />
           <strong>Investment Desk</strong>
            </p>
            
            <p className="summary text-base md:text-lg lg:text-xl">
              The Covid-19 pandemic presented the organisation with unprecedented challenges and 
              posed new demands on its financial functions, resulting in the group undertaking 
              multidimensional efforts to mitigate the impact of the crisis and to preserve financial 
              stability. One such effort was the setting up of a Family Office with a corpus fund which 
              focussed on building an ecosystem that supports innovative ideas and sustainable growth. 
              This Investment Desk works in close partnership with banking institutions, asset management 
              firms, wealth managers, non-banking financial companies, and alternative investment funds 
              to help plan for the future, achieve long term-financial growth, and make commercial and 
              social impacts on a global level.
            </p>
            <br />
           
          </div>
          <div className="imgContainer">
            <img src="/educationSchool9.jpg" />
          </div>
        </section>

        <section className="sectorInformationSection grid grid-cols-1 sm:grid-cols-2">
          <div className="imgContainerl">
            <img src="/educationSchool10.jpg" />
          </div>
          <div className="content">
            <p className="summary text-base md:text-lg lg:text-xl">
              <strong>Private Equity </strong>
            </p>
            <p className="summary text-base md:text-lg lg:text-xl">
            We are committed to fostering entrepreneurial innovation and helping growing companies position themselves for long-term growth and success. Our 40+ history of scaling businesses as well as expertise across multiple verticals enables us to provide strategic direction to companies looking to realize their true potential. From support with series funding to structuring for better growth, we partner with exceptional entrepreneurs at every stage across every sector. We aim to be a global private equity firm with a unique focus on India, with a mission to make the world a better place by investing in companies that improve the way we work and live.  
            </p>
          </div>
        </section>
        <Footer />
      </section>
    </section>
  );
}

export default SectorsPage;
