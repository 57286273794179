import React from "react";

import styles from "./styles.css";

import { useLocation, useNavigate } from "react-router-dom";

import cx from "classnames";

export default function Header() {

  const location = useLocation();
  const pathName = location.pathname;

  const navigate = useNavigate();

  const scrollToSection = (page, id) => {
    navigate(`/${page}?${id}`);
  }

  return (
    <>
      <nav class="navbar">
        <div class="navbar-container">
          <input type="checkbox" name="" id="" />
          <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
          <ul class="menu-items">
            <li><span className={cx(`${pathName == "/" ? "active" : ""}`)} onClick={() => navigate("/")}>HOME</span></li>
            <li><span className={cx(`${pathName == "/about" ? "active" : ""}`)} onClick={() => navigate("/about")}>ABOUT US</span></li>
            <div className="dropdown">
              <li> <span className={cx(`${pathName == "/sectors" ? "active" : ""}`, "dropbtn")} onClick={() => navigate("/sectors")}>SECTORS </span> </li>
              <div class="dropdown-arrow"></div>
              <div class="dropdown-content">
                <span onClick={() => scrollToSection("sectors", "education")} className="text-xs md:text-xs lg:text-base">Education</span>
                <span onClick={() => scrollToSection("sectors", "real_estate")} className="text-xs md:text-xs lg:text-base">Real Estate</span>
                <span onClick={() => scrollToSection("sectors", "agriculture")} className="text-xs md:text-xs lg:text-base">Agriculture</span>
                <span onClick={() => scrollToSection("sectors", "financial_invests")} className="text-xs md:text-xs lg:text-base">Financial Investments</span>
              </div>
            </div>
            <li><span className={cx("philanthrophy", `${pathName == "/philanthrophy" ? "active" : ""}`)} onClick={() => navigate("/philanthrophy")}>PHILANTHROPY</span></li>
            <li><span className={cx(`${pathName == "/contact" ? "active" : ""}`)} onClick={() => navigate("/contact")}>CONTACT US</span></li>
          </ul>
          <div onClick={() => navigate("/")} className="headerLogo">
            <img src="headerLogo.png" alt="Logo" />
          </div>
        </div>
      </nav>
    </>
  );
}
