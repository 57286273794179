import React, { useEffect, useRef } from 'react'

import "./styles.css";

function InfoSectionComponent(props) {

  return (
    <div ref={props.refVal} className="introSection grid grid-cols-1 sm:grid-cols-2">
      {
        props.direction == "left" && (
          <div className="imgContainer">
            <img src={props.imageSrc} />
          </div>
        )
      }
      <div className="content">
        <h1 className="header text-2xl md:text-3xl lg:text-6xl">{props.title}</h1>
        <p className="summary text-sm lg:text-xl">
          {props.summary}
        </p>
        {props.summary2 && <p className="summary text-sm lg:text-xl">
          {props.summary2} <br></br><br></br>
          <strong>{props.summary3}</strong> <br></br><br></br>
          {props.summary4}
        </p>
        }
      </div>

      {
        props.direction == "right" && (
          <div className="imgContainerRight">
            <img src={props.imageSrc} />
          </div>
        )
      }
    </div>
  )
}

export default InfoSectionComponent