import React, { useEffect, useRef } from "react";

import Header from "../../components/Header";

import Footer from "../../components/Footer";

import "./styles.css";
import InfoSectionComponent from "../../components/InfoSection";
import ContactCallUs from "../../components/ContactCallUs";
import { useLocation } from "react-router-dom";

export default function AboutPage() {

  const location = useLocation();
  const params = location.search.substr(1);

  let whoRef = useRef(params);
  let growthRef = useRef(params);
  let valuesRef = useRef(params);
  let leadersRef = useRef(params);


  useEffect(() => {
    console.log(params)

    switch (params) {
      case "who_we_are":
        window.scrollTo({ behavior: 'smooth', top: whoRef.current.offsetTop })
        break;

      case "growth_story":
        window.scrollTo({ behavior: 'smooth', top: growthRef.current.offsetTop })
        break;

      case "our_values":
        window.scrollTo({ behavior: 'smooth', top: valuesRef.current.offsetTop })
        break;  

      case "our_leaders":
        window.scrollTo({ behavior: 'smooth', top: leadersRef.current.offsetTop })
        break;

      default:
        window.scrollTo({ behavior: 'smooth', top: 0})
        break;
    }
  }, [params])


  return (
    <section className="">
      <Header />
      <InfoSectionComponent
        refVal={whoRef}
        title="Who we are"
        summary=<strong>Our Purpose</strong>
        
        
        summary2="Provide expert products and solutions to help our businesses and customers achieve their aspirations." 
        summary3="Our Vision"
        summary4="To stay committed to our core competencies while still being relevant. "
        imageSrc="/aboutSec.png"
        direction="left"
      />

      <GrowthSection refVal={growthRef} />

      <ValuesSection refVal={valuesRef} />

      <OurLeaders refVal={leadersRef} />
      <Footer />
    </section>
  );
}

const ValuesSection = ({refVal}) => {
  return (
    <div ref={refVal} className="valuesContainer grid grid-cols-1 sm:grid-cols-2">
      <div className="content">
        <h1 className="header text-2xl md:text-3xl lg:text-6xl">Our Mission</h1>
        <p className="summary text-sm lg:text-xl">AE group is dedicated to providing efficient and responsible services to businesses and customers in Real Estate, Education, Agriculture.  <br /> <br />
        With roots tracing back to 1979, AE Group started as a construction company and has since then diversified across multiple business verticals such as Real Estate, Education, Agriculture and Financial Investments.. After over four decades in business, AE Group has become synonymous with quality and our expertise places us at the forefront of our various business verticals.<br /> <br />
        We are a values-driven company that is known for our executional excellence and high operational standards. Acting with uncompromising honesty and integrity in everything we do are our core values, operating principles, 
        and guide rails permeating every area of our business. Our Governance Philosophy is to ensure fair, transparent, accountable, and ethical management in order to safeguard the interests of all stakeholders, employees, clients, and society. </p>
      </div>
      <div className="imgContainerRight"> <br></br>
        <img src="/aboutSectiontwo.jpg" alt="" />
      </div>
    </div>
  )
}

const OurLeaders = ({refVal}) => {
  return (
    <section ref={refVal} className="leadersSection">
      <div class="Abttop">
      <h1 className="header text-2xl md:text-3xl lg:text-6xl">Our Leaders</h1>
      </div>
      <div class="grid-container grid md:grid-cols-3 lg:grid-cols-4 grid-cols-1">
        <div class="grid-item">
          <img src="CoreMember1.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">AM. Emanuel</h2>
            <p class="Abtpara">Chairman</p>
          </div>
        </div>
        <div class="grid-item">
          <img src="CoreMember2.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">Hyacinth Emanuel</h2>
            <p class="Abtpara">Chairwoman - Education</p>
          </div>
        </div>

        <div class="grid-item">
          <img src="CoreMember3.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">Ashish Emanuel</h2>
            <p class="Abtpara">Managing Director</p>
          </div>
        </div>

        <div class="grid-item">
          <img src="CoreMember4.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">Sriram Ambaru</h2>
            <p class="Abtpara">Director, CFO</p>
          </div>
        </div>

        <div class="grid-item">
          <img src="CoreMember5.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">Jude Absolom</h2>
            <p class="Abtpara">Director, COO</p>
          </div>
        </div>

        <div class="grid-item">
          <img src="CoreMember6.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">Alana Emanuel</h2>
            <p class="Abtpara">Director, AKIRA</p>
          </div>
        </div>

        <div class="grid-item">
          <img src="CoreMember7.jpg" alt="" />
          <div class="Abttext-box">
            <h2 class="Abthead">Ben Rebeiro</h2>
            <p class="Abtpara">Director, AE Agro Farms</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const GrowthSection = ({refVal}) => {
  const timeLineData = [
    {
      className: "timeLineWrapper",
      year: "1979",
      title: "AMCE Builders",
      summary:
        "Established under ACME BUILDERS and operations commence in Hyderabad and Secunderbad",
    },

    {
      className: "timeLineWrapper",
      year: "1987",
      title: "St.Andrews School",
      summary: "Bowenpally, Secunderabad",
    },

    {
      className: "timeLineWrapper",
      year: "1995",
      title: "St.Andrews School",
      summary: "Maredpally, Secunderbad",
    },

    {
      className: "timeLineWrapper2",
      year: "1985",
      title: "Palika Bazar",
      summary: "1st mall in South India",
    },

    {
      className: "timeLineWrapper2",
      year: "1992",
      title: "AE Agro Farms",
    },

    {
      className: "timeLineWrapper",
      year: "2009",
      title: "AE Group",
      summary: "Rebranding",
    },

    {
      className: "timeLineWrapper2",
      year: "2007",
      title: "Real Estate Expansion",
      summary: "Bangalore",
    },
    {
      className: "timeLineWrapper",
      year: "2012",
      title: "St.Andrews School",
      summary: "Keesara, Secunderbad",
    },

    {
      className: "timeLineWrapper2",
      year: "2011",
      title: "Education Services",
    },

    {
      className: "timeLineWrapper",
      year: "2017",
      title: "JDA",
      summary: "Provident Capella",
    },
    {
      className: "timeLineWrapper",
      year: "2019",
      title: "Winmore Academy",
      summary: "Jakkur, Bangalore",
    },

    {
      className: "timeLineWrapper2",
      year: "2016",
      title: "Winmore Academy",
      summary: "Whitefield, Bangalore",
    },

    {
      className: "timeLineWrapper",
      year: "2022",
      title: "Private Equity",
      summary: "Investment Desk",
    },

    {
      className: "timeLineWrapper2",
      year: "2018",
      title: "AKIRA",
      summary: "Secunderbad",
    },
    {
      className: "timeLineWrapper2",
      year: "2021",
      title: "Crimson Tie up",
      summary: "keesara and Bowenpally",
    },
    {
      className: "timeLineWrapper2",
      year: "2023",
      title: "Crimson Tie up",
      summary: "Winmore Academy",
    },
  ];
  return (
    <section ref={refVal} class="growthStorySection">
      <h1 class="header text-2xl md:text-3xl lg:text-6xl">Our Growth Story</h1>
      <img className="timelineDesktop" src="/timeline.jpg" alt="" />
      <img className="timelineMobile" src="/timelineMobile.jpg" alt="" />
      {/* <div className="timeLineWrapper">
        {timeLineData.map((item) => {
          if (item.className == "timeLineWrapper") {
            return (
              <TimeLineSeries
                className={item.className}
                year={item.year}
                title={item.title}
                summary={item.summary}
              />
            );
          }
        })}
      </div>

      <div className="timeLineWrapper2">
        {timeLineData.map((item) => {
          if (item.className == "timeLineWrapper2") {
            return (
              <TimeLineSeries2
                className={item.className}
                year={item.year}
                title={item.title}
                summary={item.summary}
              />
            );
          }
        })}
      </div> */}
    </section>
  );
};

const TimeLineSeries = (props) => {
  return (
    <div class="timeLine">
      <div class="eventBubble"></div>
      <div class="eventLine"></div>
      <div class="eventSummary">
        <span class="year text-base md:text-lg lg:text-2xl">{props.year}</span>
        <span class="title text-xs md:text-sm lg:text-base">{props.title}</span>
        <span class="summary text-xs md:text-sm lg:text-base">
          {props.summary}
        </span>
      </div>
    </div>
  );
};

const TimeLineSeries2 = (props) => {
  return (
    <div class="timeLine">
      <div class="eventBubble"></div>
      <div class="eventLine"></div>
      <div class="eventSummary">
        <div className="contentContainer">
          <span class="title text-xs md:text-sm lg:text-base">
            {props.title}
          </span>
          <span class="summary text-xs md:text-sm lg:text-base">
            {props.summary}
          </span>
        </div>
        <span class="year text-base md:text-lg lg:text-2xl">{props.year}</span>
      </div>
    </div>
  );
};
